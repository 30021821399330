<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="View Salary Fixation" slot="title" link="/helpContent/viewSalaryFixation" />
    <salary :form="form"/>
    <ButtonGroup>
      <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
        </ButtonGroup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {} from "../api";
import salary from  '../components/salary';
import validateSelectedRows from "Mixins/validateSelectedRows";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    salary,
  },
  data() {
    return {
      form: {
                selectedStaff:null,
                appraisedBy: '',
                designation:null,
                grade: null,
                appraisalOn: "",
                effectiveFrom: "",
                basicSalary: null,
                additional: '',
                rating:null,
                pfDeduction: "",
                },
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
};
</script>

